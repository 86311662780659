import {
  Banner,
  Box,
  ContactBox,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Container,
  Heading,
  Helmet,
  IconSheets,
  Layout,
  Link,
  ResourcesTile,
  Row,
  Section,
  Theme,
  Tile,
  routesObject
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"

import React from "react"
import { graphql } from "gatsby"
import { MentalHealthCDPSForm } from "@life-without-barriers/shared-contact-form"

const { corporateThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    mentalHealthLogo: IGatsbyImageData
    waPrimaryLogo: IGatsbyImageData
    resources: {
      nodes: DocumentResource[]
    }
  }
}

const ContactBoxContents = () => (
  <ContactBox>
    <p>
      If you’d like to learn more, get in touch through our contact form or call
      us to find out more about this service.
    </p>
    <ContactUsPhoneLink phoneNumber="0418724549" />
    <ContactUsEmailLink text="cdps@lwb.org.au" link="mailto:cdps@lwb.org.au" />
    <Heading size={3} className="color-lwb-theme-darker">
      Interpreting services
    </Heading>

    <p>
      If you need an interpreter to speak to us, please call 13 14 50 and ask
      for the Culturally Diverse Psychological Service
    </p>
    <ContactUsPhoneLink phoneNumber="0418724549" />
  </ContactBox>
)

const CulturallyDiversePsychologicalServicePage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    mentalHealthLogo,
    waPrimaryLogo,
    resources
  }
}: Props) => {
  const gpForm = getResource(
    "CULTURALLY-DIVERSE-PSYCHOLOGICAL-SERVICE-GP-REFERRAL-FORM-Editable",
    resources.nodes
  )
  const generalForm = getResource(
    "CULTURALLY-DIVERSE-PSYCHOLOGICAL-SERVICE-GENERAL-REFERRAL-FORM-Editable",
    resources.nodes
  )

  return (
    <div>
      <Helmet
        title={`Culturally Diverse Psychological Service | ${siteMetadata.title}`}
        description="Culturally appropriate, evidence based psychological counselling service."
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={corporateThemeFull.medium}
      />
      <Layout
        theme={corporateThemeFull}
        fixedCTAContact={<MentalHealthCDPSForm formId="mental-health-cdps-1" />}
        fixedCTAPhoneNumber="0418724549"
      >
        <Banner
          title="Culturally Diverse Psychological Service (CDPS)"
          image={bannerImage}
          breadcrumbs={[
            routesObject.home,
            routesObject.service,
            {
              to: "/services/mental-health/",
              text: "Mental Health Support Services",
              title: "Return navigation"
            }
          ]}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>
                What is the Culturally Diverse Psychological Service?
              </Heading>
              <p>
                The Culturally Diverse Psychological Service (CDPS) is a free
                mental health service that provides short term, culturally
                sensitive, and evidence-based counselling to people from
                culturally and linguistically diverse backgrounds. This service
                is funded by the WA Primary Health Alliance.
              </p>
              <Heading size={2} topMargin="standard">
                <div id="whos-eligible">Who&apos;s eligible?</div>
              </Heading>
              <p>
                To be eligible for the CDPS you need to be all of the following:
              </p>
              <ul>
                <li className="mb2">be aged 12 years or older;</li>
                <li className="mb2">
                  from a culturally and linguistically diverse (CALD)
                  background;
                </li>
                <li className="mb2">
                  experiencing mild to moderate psychological distress;
                </li>
                <li className="mb2">
                  hold a valid Medicare Card and not be under NDIS;
                </li>
                <li className="mb2">
                  experiencing cultural, financial or language barriers to
                  accessing mainstream mental health services.
                </li>
              </ul>
              <Heading size={2} topMargin="standard">
                What if I’m not eligible?
              </Heading>
              <p>
                If you require support but are not eligible for CDPS, our team
                can connect you with other programs or services that may be able
                to help.
              </p>

              <Heading size={2} topMargin="standard">
                What services are available?
              </Heading>
              <p>
                Clients can receive up to 10 free, confidential, and culturally
                appropriate counselling sessions conducted by our experienced
                and registered mental health professionals. Along with access to
                interpreters if needed, our team of Psychologists are fluent in
                various languages to support clients throughout their time with
                the service.
              </p>
              <Heading size={2} topMargin="standard">
                Where is the CDPS located?
              </Heading>
              <p>
                We have CDPS locations throughout the Perth metro area
                including:
              </p>
              <ul>
                <li className="mb2">Leederville</li>
                <li className="mb2">Mirrabooka (and surrounds)</li>
                <li className="mb2">Langford</li>
              </ul>
              <p>
                If you live in the Perth metro area and are looking for a CDPS
                location closer to home, please contact us.
              </p>

              <Heading size={2} topMargin="standard">
                Accessing the Culturally Diverse Psychological Service
              </Heading>
              <p>
                A GP, Psychiatrist or Paediatrician referral is needed to access
                counselling through the program. If you need help to arrange a
                GP referral, download and print the letter{" "}
                <Link
                  className="color-lwb-black"
                  target="_blank"
                  to={generalForm.reference}
                >
                  Requesting a referral from your GP
                </Link>{" "}
                and take it to your next GP appointment, or contact us for
                support.
              </p>
              <Heading size={2} topMargin="standard">
                Does the CDPS offer practical support or case management?
              </Heading>
              <p>
                No, the service does not provide support with practical,
                financial, housing or legal matters or case management. It only
                offers clinical services, and two reports (after sessions 5 and
                10) are sent directly to the referring GP.
              </p>
              <Heading size={2} topMargin="standard">
                Waiting times
              </Heading>
              <p>
                Clients are allocated according to our waiting list. Our waiting
                list times vary depending on the type of referral and the
                caseload of our mental health professionals. Clients will be
                advised of an approximate waiting time when the referral is
                received.
              </p>
            </Box>
            <Box className="w-100 w-third-l mt4 mt0-l">
              <ContactBoxContents />
              <div className="mt4">
                <MentalHealthCDPSForm formId="mental-health-cdps-2" />
              </div>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>
                  For GPs, Psychiatrists and Paediatricians
                </Heading>
                <p>
                  Clients need a referral to access the service. A K10 is
                  beneficial and a Mental Health Treatment Plan from the GP is
                  optional.
                </p>
                <p>
                  To provide a referral please complete the{" "}
                  <Link
                    className="color-lwb-black"
                    target="_blank"
                    to={gpForm.reference}
                  >
                    GP referral form
                  </Link>{" "}
                  and email the completed form to{" "}
                  <Link
                    className="color-lwb-black"
                    to="mailto:cdps.referrals@lwb.org.au"
                  >
                    cdps.referrals@lwb.org.au
                  </Link>
                  {"."}
                </p>
                <p>
                  The CDPS Referral Form is available in the{" "}
                  <b>Best Practice Premier Software</b>, search <b>CALD</b>{" "}
                  Psychological Service GP Referral Form.
                </p>
                <p>
                  The CDPS is funded by the WA Primary Health Alliance (WAPHA).
                  All the counselling sessions are <b>free</b> to people that
                  meet the eligibility criteria.
                </p>
                <p>
                  The CDPS does not provide services under Medicare. MBS (Better
                  Access) sessions will remain available for the client to use
                  for other services.
                </p>
              </Box>
            </Row>
            <Row>
              <Box className="w-100">
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="REFERRAL FORM"
                  icon={
                    <IconSheets height="25" color={corporateThemeFull.medium} />
                  }
                  resources={getResourceList(GPlabels, resources.nodes)}
                />
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>For clients</Heading>
              <p>
                If you would like to access the counselling sessions through the
                CDPS:
              </p>
              <p>
                1. Check if you are <a href="#whos-eligible">eligible</a> to
                access the CDPS
              </p>
              <p>
                2. Download and take the{" "}
                <Link target="_blank" to={generalForm.reference}>
                  Requesting a referral from your GP | PDF
                </Link>{" "}
                letter to your next GP (Psychiatrist or Paediatrician)
                appointment.
              </p>
              <p>
                3. Talk to your GP (Psychiatrist or Paediatrician) and ask them
                to complete a GP referral form (preferably with a K10 and mental
                health treatment plan) on your behalf.
              </p>
              <p>
                4. If they need support with the referral process, they can
                contact us via our website, on{" "}
                <Link to="mailto:cdps.referrals@lwb.org.au">
                  cdps.referrals@lwb.org.au
                </Link>{" "}
                or calling <b>0418 724 549</b>.
              </p>
            </Box>
          </Row>
          <Row>
            <Box>
              <ResourcesTile
                className="mt4 mt5-ns lwb-shadow w-100"
                name="REFERRAL FORMS & BROCHURES"
                icon={
                  <IconSheets height="25" color={corporateThemeFull.medium} />
                }
                resources={getResourceList(generalLabels, resources.nodes)}
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading
                  className="color-lwb-theme-medium"
                  size={3}
                  topMargin="none"
                >
                  The Culturally Diverse Psychological Service is not a crisis
                  service.
                </Heading>
                <p>If you need urgent or immediate support, please call:</p>
              </Box>
            </Row>
            <Row className="flex-wrap">
              <Box className="w-60">
                <Heading size={4}>Emergency Services</Heading>
                <p>If you are concerned for your, or someone else’s safety.</p>
                <ContactUsPhoneLink
                  phoneNumber="000"
                  label="000 (triple zero)"
                />
                <Heading size={4}>
                  Mental Health Emergency Response Line (MHERL)
                </Heading>
                <ContactUsPhoneLink
                  phoneNumber="1300555788"
                  label="Metro: 1300 555 788"
                />
                <ContactUsPhoneLink
                  phoneNumber="1800676822"
                  label="Peel: 1800 676 822"
                />
              </Box>

              <Box>
                <Heading size={4}>Lifeline</Heading>
                <ContactUsPhoneLink phoneNumber="131114" label="13 11 14" />
                <Heading size={4}>Suicide Call Back Service</Heading>
                <ContactUsPhoneLink phoneNumber="1300659467" />
                <Heading size={4}>Beyondblue</Heading>
                <ContactUsPhoneLink phoneNumber="1300224636" />
              </Box>
            </Row>
          </Container>
        </Section>

        <Container>
          <div className="flex flex-column flex-row-l pb3-l">
            <Tile className="tc center pt2 pv2 ph2-m w-100 w-30-l ph0 ph2-ns">
              <GatsbyImage
                className="mt2 pa4"
                imgStyle={{ objectFit: "contain" }}
                image={getGatsbyImage(waPrimaryLogo)}
                objectFit="contain"
                objectPosition="50% 50%"
                alt=""
              />
            </Tile>
            <Tile className="tc center pt2 pv2 ph2-m w-100 w-70-l ph0 ph2-ns">
              <div className="flex flex-column flex-row-ns pa4">
                <GatsbyImage
                  className="mh2 mh0-ns w-100 w-30-ns"
                  imgStyle={{ objectFit: "contain" }}
                  image={getGatsbyImage(mentalHealthLogo)}
                  objectFit="contain"
                  objectPosition="50% 50%"
                  alt=""
                />
                <p className="mt2 i w-100 w-60-ns tl-ns center">
                  The Culturally Diverse Psychological Service is supported by
                  WA Primary Health Alliance under the Australian Government’s
                  Primary Health Networks Program.
                </p>
              </div>
            </Tile>
          </div>
        </Container>
      </Layout>
    </div>
  )
}

interface DocumentResource {
  name: string
  relativePath: string
  publicURL: string
}

const generalLabels: { [name: string]: string } = {
  "CDPS-GENERAL-REFERRAL-FORM-23-08-21": "General referral form | PDF",
  "Culturally Diverse Psychological Service - English":
    "CDPS brochure (English) | PDF",
  "Culturally Diverse Psychological Service - Arabic":
    "CDPS brochure (Arabic) | PDF",
  "Culturally Diverse Psychological Service - Burmese":
    "CDPS brochure (Burmese) | PDF",
  "Culturally Diverse Psychological Service - Dari":
    "CDPS brochure (Dari) | PDF",
  "Culturally Diverse Psychological Service - Dinka":
    "CDPS brochure (Dinka) | PDF",
  "Culturally Diverse Psychological Service - Farsi":
    "CDPS brochure (Farsi) | PDF",
  "Culturally Diverse Psychological Service - Hindi":
    "CDPS brochure (Hindi) | PDF",
  "Culturally Diverse Psychological Service - Indonesian":
    "CDPS brochure (Indonesian) | PDF",
  "Culturally Diverse Psychological Service - Kirundi":
    "CDPS brochure (Kirundi) | PDF",
  "Culturally Diverse Psychological Service - Mandarin":
    "CDPS brochure (Mandarin) | PDF",
  "Culturally Diverse Psychological Service - Somali":
    "CDPS brochure (Somali) | PDF",
  "Culturally Diverse Psychological Service - Spanish":
    "CDPS brochure (Spanish) | PDF",
  "Culturally Diverse Psychological Service - Swahili":
    "CDPS brochure (Swahili) | PDF",
  "Culturally Diverse Psychological Service - Tamil":
    "CDPS brochure (Tamil) | PDF",
  "Culturally Diverse Psychological Service - Turkish":
    "CDPS brochure (Turkish) | PDF",
  "Culturally Diverse Psychological Service - Vietnamese":
    "CDPS brochure (Vietnamese) | PDF"
}

const GPlabels: { [name: string]: string } = {
  "CDPS-GENERAL-REFERRAL-FORM-23.08.21": "CDPS GP referral form | PDF"
}

const getResourceList = (
  labels: { [name: string]: string },
  documents: DocumentResource[]
) => {
  // Sort document array in the same order the above labels are defined.
  const labelOrder = Object.keys(labels)
  const labelOrderComparer = (docA: DocumentResource, docB: DocumentResource) =>
    labelOrder.indexOf(docA.name) - labelOrder.indexOf(docB.name)
  return documents
    .sort(labelOrderComparer)
    .filter(({ name }) => !!labels[name])
    .map(({ name, publicURL }) => ({
      name: labels[name],
      reference: publicURL
    }))
}
const getResource = (resourceName: string, documents: DocumentResource[]) => {
  const labels = { ...GPlabels, ...generalLabels }
  return documents
    .filter((doc) => doc.name === resourceName)
    .map(({ name, publicURL }) => ({
      name: labels[name],
      reference: publicURL
    }))[0]
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/indian-couple.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: CONSTRAINED)
      }
    }
    mentalHealthLogo: file(
      relativePath: { regex: "/mental-health-logo-cpds.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    waPrimaryLogo: file(relativePath: { regex: "/wa-primary.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    resources: allFile(filter: { relativeDirectory: { eq: "services/cdps" } }) {
      nodes {
        name
        relativePath
        publicURL
      }
    }
  }
`

export default CulturallyDiversePsychologicalServicePage
